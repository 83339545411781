import { Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UilTrashAlt, UilPlus } from '@iconscout/react-unicons';

interface QuizModalProps {
  show: boolean;
  handleClose: () => void;
  unitId: number | null;
}

const QuizModal = ({ show, handleClose, unitId }: QuizModalProps) => {
  const [name, setName] = useState('');
  const [briefDescription, setBriefDescription] = useState('');
  const [content, setContent] = useState('');
  const [position] = useState(null);
  //   const [sectionId] = useState(1);
  const [questions, setQuestions] = useState([
    {
      position: 1,
      type: 'singleChoice',
      question: '',
      options: ['', '', '', '']
    }
  ]);

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        position: questions.length + 1,
        type: 'singleChoice',
        question: '',
        options: ['', '', '', '']
      }
    ]);
  };

  const handleRemoveQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (
    index: number,
    field: string,
    value: string
  ) => {
    setQuestions(
      questions.map((question, i) => {
        if (i === index) {
          return { ...question, [field]: value };
        }
        return question;
      })
    );
  };
  const token = localStorage.bearerToken;

  const headers = {
    Authorization: `Bearer ${token}`
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://testapi.clinicare-app.com/api/v1/admin/quiz',
        {
          name,
          briefDescription,
          content,
          position,
          sectionId: unitId,
          questions
        },
        { headers }
      );
      toast.success('Quiz créé avec succès');
      console.log('createData :', response.data);
      handleClose();
      //   window.location.reload();
    } catch (error) {
      toast.error('Erreur produit de la création');
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="p-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">
              Nom du quiz
            </Form.Label>
            <input
              className="form-control"
              name="name"
              type="text"
              placeholder="Nom du quiz"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">
              Description courte
            </Form.Label>
            <input
              className="form-control"
              name="briefDescription"
              type="text"
              placeholder="Description courte"
              value={briefDescription}
              onChange={e => setBriefDescription(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Contenu</Form.Label>
            <Form.Control
              value={content}
              onChange={e => setContent(e.target.value)}
              as="textarea"
              rows={5}
            />
          </div>
          <div className="mb-3">
            <h5 className="text-body-highlight mb-3">Questions</h5>
            {questions.map((question, index) => (
              <div key={index}>
                <Form.Label className="form-label-header mb-2">
                  Question {index + 1}
                </Form.Label>
                <input
                  className="form-control"
                  name="question"
                  type="text"
                  placeholder="Question"
                  value={question.question}
                  onChange={e =>
                    handleQuestionChange(index, 'question', e.target.value)
                  }
                />
                <Form.Label className="form-label-header mb-2">
                  Options
                </Form.Label>
                {question.options.map((option, i) => (
                  <input
                    key={i}
                    className="form-control"
                    name="option"
                    type="text"
                    placeholder="Option"
                    value={option}
                    onChange={e =>
                      handleQuestionChange(
                        index,
                        `options.${i}`,
                        e.target.value
                      )
                    }
                  />
                ))}
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleRemoveQuestion(index)}
                >
                  <UilTrashAlt size={16} />
                </button>
              </div>
            ))}
            <button
              className=" btn btn-sm btn-primary"
              onClick={handleAddQuestion}
            >
              <UilPlus size={16} />
            </button>
          </div>
          <div className="d-flex flex-between-center">
            <button className="btn btn-sm btn-danger" onClick={handleClose}>
              Close
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-success rounded-pill"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default QuizModal;
