import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// import 'quill/dist/quill.core.css';
// import { Editor } from '@tinymce/tinymce-react';
// const Delta = Quill.import('delta');

import {
  UilTrashAlt,
  UilEditAlt,
  UilCheckCircle,
  UilPlus
} from '@iconscout/react-unicons';

import TinymceEditor from 'components/base/TinymceEditor';
import axios from 'axios'; // Add axios for making HTTP requests
import { toast } from 'react-toastify';
import QuizModal from 'components/modals/QuizModal';

const CreateCourses = () => {
  const courseId = useParams();
  const [openCourse, setOpenCourse] = useState<number | null>(null);
  const [openChapter, setOpenChapter] = useState<number | null>(null);
  const [openUnity, setOpenUnity] = useState<number | null>(null);
  const [newUnitId, setNewUnitId] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState(1);
  const [faqs, setfaqs] = useState([{ id: '', question: '', answer: '' }]);
  const [objectifs, setObjectifs] = useState([{ id: '', description: '' }]);
  const [courses, setCourses] = useState({
    id: '',
    name: '',
    duration: '',
    level: '',
    description: '',
    courseOverviewDescription: '',
    sections: [
      {
        id: '',
        name: '',
        position: '',
        lessons: [
          {
            id: '',
            name: '',
            content: '',
            briefDescription: '',
            position: '',
            type: ''
          }
        ]
      }
    ]
  });

  const handleAddFaq = (faqIndex: number) => {
    setfaqs([
      ...faqs.slice(0, faqIndex),
      { id: '', question: '', answer: '' },
      ...faqs.slice(faqIndex)
    ]);
  };

  const handleAddObjectif = (objIndex: number) => {
    setObjectifs([
      ...objectifs.slice(0, objIndex),
      { id: '', description: '' },
      ...objectifs.slice(objIndex)
    ]);
  };

  const handleEditorDescriptionChange = (
    description: string,
    objIndex: number
  ) => {
    setObjectifs(prevObjectifs => {
      const updatedObjectifs = [...prevObjectifs];
      updatedObjectifs[objIndex].description = description;
      return updatedObjectifs;
    });
  };

  const token = localStorage.bearerToken;

  const headers = {
    Authorization: `Bearer ${token}`
  };

  useEffect(() => {
    async function fetchCourses() {
      try {
        // const token = getBearerToken();
        const response = await axios.get(
          `https://testapi.clinicare-app.com/api/v1/course/${courseId.id}`,
          { headers }
        );
        console.log('courses :', response.data);
        setCourses(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCourses();
  }, [courseId.id]);

  // const handleRemoveFaq = (index: number) => {
  //   const updatedFaqs = faqs.filter((_, i) => i !== index);
  //   setfaqs(updatedFaqs);
  // };

  const handleRemoveFaq = async (index: number) => {
    const faqId = faqs[index].id; // assume you have an `id` property on each FAQ object

    try {
      const response = await axios.delete(
        `https://testapi.clinicare-app.com/api/v1/faq/${faqId}`,
        { headers }
      );
      toast.success('FAQ supprimée avec succès');
      console.log('deleteData :', response);
    } catch (error) {
      console.error(error);
      toast.error('Erreur lors de la suppression de la FAQ');
    }
    setfaqs(faqs.filter((_, i) => i !== index));
  };

  const handleRemoveObjectif = async (index: number) => {
    const objectifId = objectifs[index].id; // assume you have an `id` property on each objectif object

    try {
      const response = await axios.delete(
        `https://testapi.clinicare-app.com/api/v1/course-objective/${objectifId}`,
        { headers }
      );
      toast.success('Objectif supprimé avec succès');
      console.log('deleteData :', response);
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la suppression de l'objectif");
    }
    setObjectifs(objectifs.filter((_, i) => i !== index));
  };

  const handleEditCourse = () => {
    const updatedCourses = { ...courses };
    // updatedCourses.name = document.getElementById(`chapter-name-0`).value;
    // updatedCourses.level =
    //   document.getElementById(`validationCustom04-0`).value;
    // updatedCourses.status =
    //   document.getElementById(`validationCustom04-0`).value;
    // updatedCourses.description =
    //   document.getElementById(`cours-description-0`).value;
    setCourses(updatedCourses);
  };

  const handleAddUnit = () => {
    const newUnit = {
      id: '',
      name: '',
      position: '',
      lessons: [
        {
          id: '',
          name: '',
          content: '',
          briefDescription: '',
          position: '',
          type: ''
        }
      ]
    };
    setCourses({ ...courses, sections: [...courses.sections, newUnit] });
  };

  const handleUnitChange = (
    unitIndex: number,
    event: { target: { name: string; value: string } }
  ) => {
    const updatedUnits = [...courses.sections];
    const updatedUnit = {
      ...updatedUnits[unitIndex],
      [event.target.name]: event.target.value
    };
    updatedUnits[unitIndex] = updatedUnit;
    setCourses({ ...courses, sections: updatedUnits });
  };

  const handleAddChapter = (unitIndex: number) => {
    const updatedCourses = { ...courses };
    const updatedUnits = [...updatedCourses.sections];
    const updatedUnit = updatedUnits[unitIndex];
    updatedUnit.lessons = [
      ...updatedUnit.lessons,
      {
        id: '',
        name: '',
        content: '',
        briefDescription: '',
        position: '',
        type: ''
      }
    ];
    updatedCourses.sections = updatedUnits;
    setCourses(updatedCourses);
  };

  const handleChapterChange = (
    unitIndex: number,
    chapterIndex: number,
    event: { target: { name: string; value: string } }
  ) => {
    const updatedUnits = [...courses.sections];
    const updatedUnit = { ...updatedUnits[unitIndex] };
    const updatedChapters = [...updatedUnit.lessons];
    const updatedChapter = {
      ...updatedChapters[chapterIndex],
      [event.target.name]: event.target.value
    };
    updatedChapters[chapterIndex] = updatedChapter;
    updatedUnit.lessons = updatedChapters;
    updatedUnits[unitIndex] = updatedUnit;
    setCourses({ ...courses, sections: updatedUnits });
  };

  const handleRemoveUnit = async (unitIndex: number) => {
    // const courseId = courses.id; // assume you have an `id` property on each course object
    const unitId = courses.sections[unitIndex].id; // assume you have an `id` property on each unit object

    try {
      const response = await axios.delete(
        `https://testapi.clinicare-app.com/api/v1/section/${unitId}`,
        { headers }
      );
      toast.success('Unité supprimée avec succès');
      console.log('deleteData :', response);
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la suppression de l'unité");
    }
    setCourses({
      ...courses,
      sections: courses.sections.filter((_, i) => i !== unitIndex)
    });
  };

  const handleRemoveChapter = async (
    unitIndex: number,
    chapterIndex: number
  ) => {
    const chapterId = courses.sections[unitIndex].lessons[chapterIndex].id; // assume you have an `id` property on each chapter object

    try {
      const response = await axios.delete(
        `https://testapi.clinicare-app.com/api/v1/lesson/${chapterId}`,
        { headers }
      );
      toast.success('Chapitre supprimé avec succès');
      console.log('deleteData :', response);
    } catch (error) {
      console.error(error);
      toast.error('Erreur lors de la suppression du chapitre');
    }
    setCourses({
      ...courses,
      sections: courses.sections.map((section, sectionIndex) => {
        if (sectionIndex === unitIndex) {
          return {
            ...section,
            lessons: section.lessons.filter((_, i) => i !== chapterIndex)
          };
        }
        return section;
      })
    });
  };

  const handleEditUnit = (unitIndex: number) => {
    const updatedUnits = [...courses.sections];
    const updatedUnit = { ...updatedUnits[unitIndex] };
    // updatedUnit.name = document.getElementById(`unit-name-${unitIndex}`).value;
    updatedUnits[unitIndex] = updatedUnit;
    setCourses({ ...courses, sections: updatedUnits });
  };

  const validAddUnit = async (unitIndex: number) => {
    // const courseId = courses.id; // assume you have an `id` property on each course object
    const newUnit = {
      name: courses.sections[unitIndex].name,
      position: courses.sections[unitIndex].position,
      courseId: courseId.id
    };

    try {
      const response = await axios.post(
        'https://testapi.clinicare-app.com/api/v1/section',
        newUnit,
        { headers }
      );
      const newUnitId = response.data.id; // Get the ID of the newly created unit
      setNewUnitId(newUnitId); // Set newUnitId state
      toast.success('Unité créée avec succès');
      console.log('createData :', response);
      // validAddChapter(newUnitId, chapterIndex);
    } catch (error) {
      console.error(error);
      toast.error('Error de création lors de la section');
    }
  };

  const validAddChapter = async (unitIndex: number, chapterIndex: number) => {
    // const unitId = courses.sections[unitIndex].id; // assume you have an `id` property on each unit object
    // console.log(unitId);
    const newChapter = {
      name: courses.sections[unitIndex].lessons[chapterIndex].name,
      content: courses.sections[unitIndex].lessons[chapterIndex].content,
      briefDescription:
        courses.sections[unitIndex].lessons[chapterIndex].briefDescription,
      position: courses.sections[unitIndex].lessons[chapterIndex].position,
      type: 'text',
      sectionId: newUnitId
    };

    try {
      const response = await axios.post(
        `https://testapi.clinicare-app.com/api/v1/lesson`,
        newChapter,
        { headers }
      );
      toast.success('Chapitre créer avec  success');
      console.log('lesson created:', response);
    } catch (error) {
      console.error(error);
      toast.error('Error de création lors du chapitre');
    }
  };

  const handleEditorChange = (
    unitIndex: number,
    chapterIndex: number,
    content: string
  ) => {
    setCourses(prevCourses => {
      const updatedCourses = { ...prevCourses };
      const updatedUnit = updatedCourses.sections[unitIndex];
      const updatedChapter = updatedUnit.lessons[chapterIndex];
      updatedChapter.content = content;
      return updatedCourses;
    });
  };

  const handleEditorBriefDescriptionChange = (
    unitIndex: number,
    chapterIndex: number,
    briefDescription: string
  ) => {
    setCourses(prevCourses => {
      const updatedCourses = { ...prevCourses };
      const updatedUnit = updatedCourses.sections[unitIndex];
      const updatedChapter = updatedUnit.lessons[chapterIndex];
      updatedChapter.briefDescription = briefDescription;
      return updatedCourses;
    });
  };

  const validAddObjectif = async () => {
    try {
      const response = await axios.post(
        'https://testapi.clinicare-app.com/api/v1/course-objective',
        {
          description: objectifs[objectifs.length - 1].description,
          courseId: courseId.id
        },
        { headers }
      );
      const newObjectif = response.data;
      setObjectifs(prevObjectifs => [...prevObjectifs, newObjectif]);
      toast.success('Objectif créé avec succès');
    } catch (error) {
      console.error(error);
      toast.error("Erreur lors de la création de l'objectif");
    }
  };

  const validAddFaq = async () => {
    try {
      const response = await axios.post(
        'https://testapi.clinicare-app.com/api/v1/faq',
        {
          question: faqs[faqs.length - 1].question,
          answer: faqs[faqs.length - 1].answer,
          courseId: courseId.id
        },
        { headers }
      );
      const newFaq = response.data;
      setfaqs(prevFaqs => [...prevFaqs, newFaq]);
      toast.success('FAQ créée avec succès');
    } catch (error) {
      console.error(error);
      toast.error('Erreur lors de la création de la FAQ');
    }
  };

  // Popup d'ajout de quizs
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
    // setNewUnitId(newUnitId); // Set the modalUnitId state
  };

  const toogleClosePopup = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    async function fetchObjectifs() {
      try {
        const response = await axios.get(
          `https://testapi.clinicare-app.com/api/v1/course/${courseId.id}/objectives `,
          { headers }
        );
        console.log('data :', response.data);
        setObjectifs(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchObjectifs();
  }, []);

  useEffect(() => {
    async function fetchFaqs() {
      try {
        const response = await axios.get(
          `https://testapi.clinicare-app.com/api/v1/course/${courseId.id}/faqs `,
          { headers }
        );
        console.log('data :', response.data);
        setfaqs(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchFaqs();
  }, []);

  return (
    <div
      className="card theme-wizard mb-5"
      data-theme-wizard="data-theme-wizard"
    >
      <div className="bg-white shadow-lg rounded-lg overflow-hidden">
        <div className="px-6 py-4">
          <div className="card-header bg-100 pt-3 pb-2 border-bottom-0">
            <ul className="nav justify-content-between nav-wizard">
              {['Cours', 'Objectifs', 'Faq'].map((tab, index) => (
                <li className="nav-item" key={index}>
                  <button
                    className={`nav-link p-50 py-2 px-2 ${
                      activeTab === index + 1
                        ? 'active text-blue-500'
                        : 'text-gray-700'
                    }`}
                    onClick={() => setActiveTab(index + 1)}
                  >
                    <div className="items-center text-center d-inline-block">
                      <span className="nav-item-circle">
                        <i
                          className={`fas ${
                            index === 0
                              ? 'fa-lock'
                              : index === 1
                                ? 'fa-user'
                                : 'fa-question'
                          }`}
                        ></i>
                      </span>
                      <span className="d-none d-md-block mt-1 fs--1">
                        {tab}
                      </span>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
          {activeTab === 1 && (
            <div className="tab-content">
              <div>
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item border-top border-300">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        onClick={() =>
                          setOpenChapter(openChapter === 1 ? null : 1)
                        }
                      >
                        <span>
                          Titre du cours
                          <button
                            className="btn btn-sm btn-primary ml-2"
                            onClick={() => handleEditCourse()}
                          >
                            <UilEditAlt size={14} />
                          </button>
                        </span>
                      </button>
                    </h2>
                    {openChapter === 1 && (
                      <div
                        className="accordion-collapse collapse show"
                        id="collapseOne"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body pt-0">
                          <form className="row g-3 needs-validation">
                            <div className="col-md-4">
                              <label
                                className="form-label"
                                htmlFor="validationCustom01"
                              >
                                Nom du cours
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Titre du cours"
                                id={`chapter-name-${courses.id}`}
                                value={courses.name}
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="col-md-4">
                              <label
                                className="form-label"
                                htmlFor="validationCustom04"
                              >
                                Le niveau du cours
                              </label>
                              <select
                                className="form-select"
                                id="validationCustom04"
                                value={courses.level}
                                required
                              >
                                <option selected>Choose...</option>
                                <option value="beginner">beginner</option>
                                <option value="intermediate">
                                  intermediate
                                </option>
                                <option value="advanced">advanced</option>
                              </select>
                              <div className="invalid-feedback">
                                Please select a valid state.
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label
                                className="form-label"
                                htmlFor="validationCustom01"
                              >
                                Nom du cours
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Titre du cours"
                                id={`chapter-name-${courses.id}`}
                                value={courses.duration}
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                            <div className="mb-2">
                              <label
                                className="form-label"
                                htmlFor={`cours-description-${courses.description}`}
                              >
                                la description du cours
                              </label>
                              <textarea
                                className="form-control"
                                rows={4}
                                placeholder="La description"
                                id={`cours-description-${courses.id}`}
                                value={courses.description}
                              ></textarea>
                            </div>
                            <div className="mb-2">
                              <label
                                className="form-label"
                                htmlFor={`cours-description-${courses.courseOverviewDescription}`}
                              >
                                la description du cours
                              </label>
                              <textarea
                                className="form-control"
                                rows={4}
                                placeholder="La description"
                                id={`cours-description-${courses.id}`}
                                value={courses.courseOverviewDescription}
                              ></textarea>
                            </div>
                          </form>
                          <div className="mb-2">
                            <button
                              className="btn btn-primary"
                              onClick={() => handleAddUnit()}
                            >
                              Ajouter une unité
                            </button>
                          </div>
                          {courses.sections.map((unit, unitIndex) => (
                            <div
                              key={unitIndex}
                              className="accordion"
                              id="accordionUnit"
                            >
                              <div className="accordion-item border-top border-300">
                                <h2
                                  className="accordion-header"
                                  id="headingUnit"
                                >
                                  <button
                                    className="accordion-button"
                                    type="button"
                                    onClick={() =>
                                      setOpenUnity(
                                        openUnity === unitIndex + 1
                                          ? null
                                          : unitIndex + 1
                                      )
                                    }
                                  >
                                    <span>
                                      Unité {unitIndex + 1}
                                      <button
                                        className="btn btn-sm btn-danger ml-2"
                                        onClick={() =>
                                          handleRemoveUnit(unitIndex)
                                        }
                                      >
                                        <UilTrashAlt size={14} />
                                      </button>
                                      <button
                                        className="btn btn-sm btn-primary ml-2"
                                        onClick={() =>
                                          handleEditUnit(unitIndex)
                                        }
                                      >
                                        <UilEditAlt size={14} />
                                      </button>
                                    </span>
                                  </button>
                                </h2>
                                {openUnity === unitIndex + 1 && (
                                  <div
                                    className="accordion-collapse collapse show"
                                    id={`collapseUnit-${unitIndex}`}
                                    aria-labelledby="headingUnit"
                                    data-bs-parent="#accordionUnit"
                                  >
                                    <div className="accordion-body pt-0">
                                      <form className="row g-3 needs-validation">
                                        <div className="col-md-6">
                                          <label
                                            className="form-label"
                                            htmlFor={`unit-name-${unitIndex}`}
                                          >
                                            Titre de l'unité
                                          </label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            placeholder="Nom de l'unité"
                                            // id={`unit-name-${unitIndex}`}
                                            value={unit.name}
                                            onChange={e =>
                                              handleUnitChange(unitIndex, e)
                                            }
                                          />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                          <label className="form-label">
                                            La position de l'unité
                                          </label>
                                          <input
                                            className="form-control"
                                            type="number"
                                            name="position"
                                            value={unit.position}
                                            onChange={e =>
                                              handleUnitChange(unitIndex, e)
                                            }
                                          />
                                        </div>
                                      </form>
                                      <span className="">
                                        <div className="mb-4">
                                          <button
                                            className="btn btn-sm btn-success  mb-1"
                                            type="submit"
                                            onClick={() =>
                                              validAddUnit(unitIndex)
                                            }
                                          >
                                            <UilCheckCircle size={20} />
                                          </button>
                                        </div>
                                        <div className="mb-4">
                                          <button
                                            className="btn btn-sm btn-success  mb-1"
                                            type="submit"
                                            onClick={() => togglePopup()}
                                          >
                                            Ajouter un quizz
                                          </button>
                                        </div>
                                      </span>

                                      {unit.lessons.map(
                                        (chapter, chapterIndex) => (
                                          <div
                                            key={chapterIndex}
                                            className="accordion"
                                            id="accordionUnit"
                                          >
                                            <div className="accordion-item border-top border-300">
                                              <h2
                                                className="accordion-header"
                                                id="headingUnit"
                                              >
                                                <button
                                                  className="accordion-button"
                                                  type="button"
                                                  onClick={() =>
                                                    setOpenCourse(
                                                      openCourse ===
                                                        chapterIndex + 1
                                                        ? null
                                                        : chapterIndex + 1
                                                    )
                                                  }
                                                >
                                                  <span>
                                                    Chapitre {chapterIndex + 1}
                                                    <button
                                                      className="btn btn-sm btn-danger ml-2"
                                                      onClick={() =>
                                                        handleRemoveChapter(
                                                          unitIndex,
                                                          chapterIndex
                                                        )
                                                      }
                                                    >
                                                      <UilTrashAlt size={14} />
                                                    </button>
                                                    <button
                                                      className="btn btn-sm btn-primary ml-2"
                                                      // onClick={() =>
                                                      //   handleEditChapter(
                                                      //     courseIndex,
                                                      //     unitIndex,
                                                      //     chapterIndex
                                                      //   )
                                                      // }
                                                    >
                                                      <UilEditAlt size={14} />
                                                    </button>
                                                  </span>
                                                </button>
                                              </h2>
                                              {openCourse ===
                                                chapterIndex + 1 && (
                                                <div
                                                  className="accordion-collapse collapse show"
                                                  id={`collapseUnit-${chapterIndex}`}
                                                  aria-labelledby="headingUnit"
                                                  data-bs-parent="#accordionUnit"
                                                >
                                                  <div className="accordion-body pt-0">
                                                    <form className="row g-3 needs-validation">
                                                      <div className="col-md-4">
                                                        <label
                                                          className="form-label"
                                                          htmlFor={`unit-name-${chapterIndex}`}
                                                        >
                                                          Titre du chapitre
                                                        </label>
                                                        <input
                                                          className="form-control"
                                                          type="text"
                                                          name="name"
                                                          placeholder="Nom du chapitre"
                                                          value={chapter.name}
                                                          onChange={e =>
                                                            handleChapterChange(
                                                              unitIndex,
                                                              chapterIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-md-4">
                                                        <label
                                                          className="form-label"
                                                          htmlFor={`unit-name-${chapterIndex}`}
                                                        >
                                                          Position du chapitre
                                                        </label>
                                                        <input
                                                          className="form-control"
                                                          type="number"
                                                          name="position"
                                                          placeholder=""
                                                          id={`unit-name-${chapterIndex}`}
                                                          value={
                                                            chapter.position
                                                          }
                                                          onChange={e =>
                                                            handleChapterChange(
                                                              unitIndex,
                                                              chapterIndex,
                                                              e
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                      <div className="col-md-4">
                                                        <label
                                                          className="form-label"
                                                          htmlFor="validationCustom04"
                                                        >
                                                          le type
                                                        </label>
                                                        <select
                                                          className="form-select"
                                                          id="validationCustom04"
                                                          value={chapter.type}
                                                          onChange={e =>
                                                            handleChapterChange(
                                                              unitIndex,
                                                              chapterIndex,
                                                              e
                                                            )
                                                          }
                                                          required
                                                        >
                                                          {/* <option selected value="">
                                                            Choisir le type
                                                          </option> */}
                                                          <option value="text">
                                                            text
                                                          </option>
                                                          <option value="video">
                                                            video
                                                          </option>
                                                        </select>
                                                        <div className="invalid-feedback">
                                                          Please select a valid
                                                          state.
                                                        </div>
                                                      </div>
                                                      <div className="mb-2">
                                                        <label
                                                          className="form-label"
                                                          htmlFor={`unit-description-${chapterIndex}`}
                                                        >
                                                          Descriprion de
                                                          l'aperçu du chapitre
                                                        </label>
                                                        {/* <Editor
                                                          value={
                                                            chapter.content
                                                          }
                                                          onEditorChange={(
                                                            content
                                                            // editor
                                                          ) =>
                                                            handleEditorChange(
                                                              unitIndex,
                                                              chapterIndex,
                                                              content
                                                            )
                                                          }
                                                          init={{
                                                            height: 500,
                                                            menubar: false,
                                                            plugins: [
                                                              'advlist autolink lists link image charmap print preview anchor'
                                                            ],
                                                            toolbar:
                                                              'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
                                                          }}
                                                        /> */}
                                                        <TinymceEditor
                                                          value={
                                                            chapter.content
                                                          }
                                                          onChange={content =>
                                                            handleEditorChange(
                                                              unitIndex,
                                                              chapterIndex,
                                                              content
                                                            )
                                                          }
                                                        />
                                                      </div>

                                                      <div className="mb-2">
                                                        <label
                                                          className="form-label"
                                                          htmlFor={`unit-description-${chapterIndex}`}
                                                        >
                                                          Bref description du
                                                          chapitre
                                                        </label>
                                                        {/* <Editor
                                                          value={
                                                            chapter.briefDescription
                                                          }
                                                          onEditorChange={(
                                                            briefDescription
                                                            // editor
                                                          ) =>
                                                            handleEditorBriefDescriptionChange(
                                                              unitIndex,
                                                              chapterIndex,
                                                              briefDescription
                                                            )
                                                          }
                                                          init={{
                                                            height: 500,
                                                            menubar: false,
                                                            plugins: [
                                                              'advlist autolink lists link image charmap print preview anchor'
                                                            ],
                                                            toolbar:
                                                              'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
                                                          }}
                                                        /> */}
                                                        <TinymceEditor
                                                          value={
                                                            chapter.briefDescription
                                                          }
                                                          onChange={briefDescription =>
                                                            handleEditorBriefDescriptionChange(
                                                              unitIndex,
                                                              chapterIndex,
                                                              briefDescription
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </form>
                                                    <span>
                                                      <div className="mb-4 space">
                                                        <button
                                                          className="btn btn-primary"
                                                          onClick={() =>
                                                            handleAddChapter(
                                                              unitIndex
                                                            )
                                                          }
                                                        >
                                                          Ajouter une chapitre
                                                        </button>
                                                        <button
                                                          className="btn btn-sm btn-success  mx-10"
                                                          onClick={() =>
                                                            validAddChapter(
                                                              unitIndex,
                                                              chapterIndex
                                                            )
                                                          }
                                                        >
                                                          <UilCheckCircle
                                                            size={20}
                                                          />
                                                        </button>
                                                      </div>
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className="row">
              <div className="col-md-12">
                {objectifs.map((obj, index) => (
                  <>
                    <form>
                      <div key={obj.id} className="form-group row">
                        <div className="col-md-12">
                          <label>Description</label>
                          <TinymceEditor
                            value={obj.description}
                            onChange={description =>
                              handleEditorDescriptionChange(description, index)
                            }
                          />
                        </div>

                        <div className="col-md-4 text-right mb-2">
                          {index > 0 && (
                            <button
                              className="btn btn-danger"
                              onClick={() => handleRemoveObjectif(index)}
                            >
                              <UilTrashAlt size={14} />
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                    <div className="col-md-4">
                      <button
                        className="btn btn-sm btn-success  mb-2"
                        // type="submit"
                        onClick={() => validAddObjectif()}
                      >
                        <UilCheckCircle size={18} />
                      </button>
                    </div>
                  </>
                ))}
                <div className="form-group row">
                  <div className="col-md-12 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => handleAddObjectif(objectifs.length)}
                    >
                      <UilPlus size={16} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeTab === 3 && (
            <div className="row">
              <div className="col-md-12">
                {faqs.map((faq, index) => (
                  <>
                    <form>
                      <div key={index} className="form-group row">
                        <div className="col-md-6">
                          <label>Question</label>
                          <input
                            type="text"
                            className="form-control"
                            value={faq.question}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Réponse</label>
                          <input
                            type="text"
                            className="form-control"
                            value={faq.answer}
                          />
                        </div>
                        <div className="col-md-6 mb-2">
                          {index > 0 && (
                            <button
                              className="btn btn-danger"
                              onClick={() => handleRemoveFaq(index)}
                            >
                              <UilTrashAlt size={16} />
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                    <div className="col-md-4">
                      <button
                        className="btn btn-sm btn-success  mb-2"
                        onClick={() => validAddFaq()}
                      >
                        <UilCheckCircle size={18} />
                      </button>
                    </div>
                  </>
                ))}
                <div className="form-group row">
                  <div className="col-md-12 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => handleAddFaq(faqs.length)}
                    >
                      <UilPlus size={16} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showPopup && (
        <QuizModal
          show={true}
          handleClose={() => toogleClosePopup()}
          unitId={newUnitId}
        />
      )}
    </div>
  );
};

export default CreateCourses;
