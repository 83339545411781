import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
// import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { members } from 'data/members';
import OffresTable, {
  membersTablecolumns
} from 'components/tables/OffresTable';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// import { OffreModal } from 'components/modals/Modal';
import { useState } from 'react';
import ReviewModal from 'components/modals/ReviewModal';

const Quizs = () => {
  const table = useAdvanceTable({
    data: members,
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  // Popup d'ajout d'offre
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const toogleClosePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <div>
      {/* <PageBreadcrumb items={memberBreadcrumbItems} /> */}
      <div className="mb-9">
        <h2 className="mb-5">Listes des offres</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-3">
              <Col xs="auto">
                <SearchBox
                  placeholder="Recherchez une offre"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              ></Col>
              <Col xs="auto">
                {/* <Button variant="link" className="text-body me-4 px-0">
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Export
                </Button> */}
                <Button variant="primary" onClick={togglePopup}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter une offre
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <OffresTable />
          </div>
        </AdvanceTableProvider>
      </div>
      {showPopup && (
        <ReviewModal show={true} handleClose={() => toogleClosePopup()} />
      )}
    </div>
  );
};

export default Quizs;
