import { ColumnDef } from '@tanstack/react-table';
// import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
// import Avatar from 'components/base/Avatar';
import { Member } from 'data/members';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ActivityDeleteModal from 'components/modals/ActivityDeleteModal';
import DocumentUpdateModal from 'components/modals/DocumentUpdateModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const membersTablecolumns: ColumnDef<Member>[] = [];
interface Document {
  id: number;
  name: string;
  description: string;
  typeContent: string;
  countryId: number;
  domainId: number;
  departmentId: number;
  domainLibelle: string;
  departementLibelle: string;
  countryLibelle: string;
}
const DocumentsTables = () => {
  // Popup de suppression de documents
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const handleOpenModal = (item: { id: number; name: string }) => {
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        const response = await fetch(
          `http://localhost:3004/api/v1/document/${itemToDelete.id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.ok) {
          toast.success('Document supprimer avec succès');
          console.log('Item supprimé');
          // Mettre à jour l'état ou effectuer d'autres actions après la suppression
        } else {
          toast.error('Erreur produit lors de la suppression');
          console.error('Erreur de suppression', response.statusText);
        }
      } catch (error) {
        toast.error('Erreur produit lors de la suppression');
        console.error('Erreur de suppression', error);
      } finally {
        setIsModalOpen(false);
        window.location.reload();
      }
    }
  };

  // Popup de mise à jour d'activité
  const [documentToUpdate, setDocumentToUpdate] = useState<{
    id: number;
    name: string;
    description: string;
    typeContent: string;
    countryId: number;
    departmentId: number;
    domainId: number;
  } | null>(null);

  const handleUpdateOpenModal = (document: {
    id: number;
    name: string;
    description: string;
    typeContent: string;
    countryId: number;
    departmentId: number;
    domainId: number;
  }) => {
    setDocumentToUpdate(document);
    setIsModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsModalOpen(false);
    setDocumentToUpdate(null);
  };

  const handleUpdateDocument = async (updatedDocument: {
    id: number;
    name: string;
    description: string;
    typeContent: string;
    countryId: number;
    departmentId: number;
    domainId: number;
  }) => {
    try {
      const response = await fetch(
        `http://localhost:3004/api/v1/document/${updatedDocument.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: updatedDocument.name,
            description: updatedDocument.description,
            domainId: updatedDocument.domainId,
            departmentId: updatedDocument.departmentId,
            countryId: updatedDocument.countryId
          })
        }
      );

      if (response.ok) {
        toast.success('Document mise à jour avec succès');
        console.log('Activity updated successfully', response);
        // Mettre à jour l'état ou effectuer d'autres actions après la mise à jour
      } else {
        toast.error('Error de mise à jour');
        console.error('Erreur de mise à jour', response.statusText);
      }
    } catch (error) {
      toast.error('Error de mise à jour');
      console.error('Erreur de mise à jour', error);
    } finally {
      setIsModalOpen(false);
      window.location.reload();
    }
  };

  const [documents, setDocument] = useState<Document[]>([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    async function fetchDocument() {
      try {
        const response = await axios.get(
          'http://localhost:3004/api/v1/document'
        );
        console.log('data :', response.data);
        setDocument(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchDocument();
  }, []);
  return (
    <div
      id="tableExample"
      data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
    >
      <div className="table-responsive">
        <table className="table table-sm fs--1 mb-0">
          <thead>
            <tr>
              <th className="sort border-top ps-3" data-sort="number">
                N°
              </th>
              <th className="sort border-top ps-3" data-sort="libelle">
                Noms
              </th>
              <th className="sort border-top ps-3" data-sort="libelle">
                Type de contenu
              </th>
              <th className="sort border-top" data-sort="description">
                Descriptions
              </th>
              <th className="sort border-top" data-sort="domain">
                Domaine
              </th>
              <th className="sort border-top" data-sort="pdf">
                Pays
              </th>
              <th className="sort border-top" data-sort="picture">
                Departement
              </th>
              <th
                className="sort text-end align-middle pe-0 border-top"
                scope="col"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {documents.map(document => (
              <tr key={document.id}>
                <td className="align-middle ps-3 number">{document.id}</td>
                <td className="align-middle ps-3 name">{document.name}</td>
                <td className="align-middle libelle">{document.typeContent}</td>
                <td className="align-middle description">
                  {showFullDescription
                    ? document.description
                    : `${document.description.slice(0, 100)}...`}
                  {document.description.length > 100 && (
                    <button
                      className="btn btn-link p-0 ms-2"
                      onClick={() =>
                        setShowFullDescription(!showFullDescription)
                      }
                    >
                      {showFullDescription ? 'Voir moins' : 'Voir plus'}
                    </button>
                  )}
                </td>
                <td className="align-middle libelle">
                  {document.domainLibelle}
                </td>
                <td className="align-middle country">
                  {document.countryLibelle}
                </td>
                <td className="align-middle departement">
                  {document.departementLibelle}
                </td>
                <td className="align-middle white-space-nowrap text-end pe-0">
                  <div className="font-sans-serif btn-reveal-trigger position-static">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="me-2"
                      onClick={() =>
                        handleOpenModal({
                          id: document.id,
                          name: document.name
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="me-2"
                      onClick={() =>
                        handleUpdateOpenModal({
                          id: document.id,
                          name: document.name,
                          description: document.description,
                          typeContent: document.typeContent,
                          countryId: document.countryId,
                          departmentId: document.departmentId,
                          domainId: document.domainId
                        })
                      }
                    />{' '}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {itemToDelete && (
        <ActivityDeleteModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          itemName={itemToDelete.name}
        />
      )}
      {documentToUpdate && (
        <DocumentUpdateModal
          open={isModalOpen}
          onClose={handleUpdateCloseModal}
          onUpdate={handleUpdateDocument}
          document={documentToUpdate}
        />
      )}
    </div>
  );
};

export default DocumentsTables;
