import { Form, Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
interface ReviewModalProps {
  show: boolean;
  handleClose: () => void;
}
interface Domain {
  id: number;
  liblelle: string;
  description: string;
  country: string;
}

interface country {
  id: number;
  libelle: string;
}

interface departement {
  id: number;
  libelle: string;
}
const DocumentModal = ({ show, handleClose }: ReviewModalProps) => {
  /// Affichage des domaines
  const [domains, setDomains] = useState<Domain[]>([]);

  useEffect(() => {
    async function fetchDomains() {
      try {
        const response = await axios.get('http://localhost:3002/api/v1/domain');
        console.log('dataDomaine :', response.data);
        setDomains(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchDomains();
  }, []);

  ///Affichage des pays
  const [countries, setCountries] = useState<country[]>([]);

  useEffect(() => {
    async function fetchCountries() {
      try {
        const response = await axios.get(
          'http://localhost:3001/api/v1/country'
        );
        console.log('dataCoutries :', response.data);
        setCountries(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCountries();
  }, []);

  /// Afficher les départements d'un pays chosis
  const [departments, setDepartments] = useState<departement[]>([]);
  const [selectedCountryId, setSelectedCountry] = useState('');

  useEffect(() => {
    async function fetchDepartments() {
      if (selectedCountryId) {
        try {
          const response = await axios.get(
            `http://localhost:3001/api/v1/departements/country/${selectedCountryId}`
          );
          console.log('dataDepartement :', response.data);
          setDepartments(response.data);
        } catch (error) {
          console.error('Error fetching departments:', error);
        }
      }
    }
    fetchDepartments();
  }, [selectedCountryId]);

  const handleCountryChange = (e: { target: { value: string } }) => {
    const country = e.target.value;
    setSelectedCountry(country);
  };

  /// Création d'une activité
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  // const [typeContent, setTypeContent] = useState('');
  const [departmentId, setDepartementId] = useState('');
  const [domainId, setDomainId] = useState('');
  const [url_document, setFile] = useState<File | ''>('');

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    // formData.append('typeContent', typeContent);
    formData.append('domainId', domainId);
    formData.append('departmentId', departmentId);
    formData.append('countryId', selectedCountryId);
    formData.append('url_document', url_document);

    try {
      const response = await axios.post(
        'http://localhost:3004/api/v1/document',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      console.log('createData :', response.data);
      handleClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="p-4">
        <form
          action="/document"
          method="post"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">
              Nom du document
            </Form.Label>
            <input
              className="form-control"
              name="name"
              value={name}
              type="text"
              placeholder="Le nom du document"
              onChange={e => setName(e.target.value)}
            />
          </div>
          {/* <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Type de contenu</Form.Label>
            <input
              className="form-control"
              name="TypeContent"
              value={typeContent}
              type="text"
              placeholder="Le type de document exemple: DOCUMENT"
              onChange={e => setTypeContent(e.target.value)}
            />
          </div> */}
          <div className="mb-3">
            <h5 className="text-body-highlight mb-3">Description</h5>
            <Form.Control
              as="textarea"
              onChange={e => setDescription(e.target.value)}
              value={description}
              rows={5}
            />
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Domaine</Form.Label>
            <Form.Select onChange={e => setDomainId(e.target.value)}>
              {domains.map(domain => (
                <option key={domain.id} value={domain.id}>
                  {domain.liblelle}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">Pays</Form.Label>
            <Form.Select
              onChange={handleCountryChange}
              value={selectedCountryId}
            >
              {countries.map(country => (
                <option key={country.id} value={country.id}>
                  {country.libelle}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">
              Departement
            </Form.Label>
            <Form.Select onChange={e => setDepartementId(e.target.value)}>
              {departments.map(department => (
                <option key={department.id} value={department.id}>
                  {department.libelle}
                </option>
              ))}
            </Form.Select>
          </div>

          <div className="mb-3">
            <Form.Label className="form-label-header mb-2">
              Ajouter le document
            </Form.Label>
            <input
              className="form-control"
              name="url_document"
              type="file"
              onChange={e => {
                const files = e.target.files;
                if (files && files.length > 0) {
                  setFile(files[0]);
                }
              }}
            />
          </div>
          <div className="d-flex flex-between-center">
            <button className="btn-danger" onClick={handleClose}>
              Close
            </button>
            <button type="submit" className="rounded-pill">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default DocumentModal;
