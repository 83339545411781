import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';

interface UpdateModalProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (updatedDomain: {
    id: number;
    liblelle: string;
    description: string;
    country: string;
  }) => void;
  domain: {
    id: number;
    liblelle: string;
    description: string;
    country: string;
  } | null;
}

interface Country {
  id: number;
  libelle: string;
}
const ActivityUpdateModal: React.FC<UpdateModalProps> = ({
  open,
  onClose,
  onUpdate,
  domain
}) => {
  const [liblelle, setLiblelle] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState<Country[]>([]);

  useEffect(() => {
    if (domain) {
      setLiblelle(domain.liblelle);
      setDescription(domain.description);
      setCountry(domain.country);
    }
  }, [domain]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`http://localhost:3001/api/v1/country`);
        if (response.ok) {
          const data = await response.json();
          setCountries(data);
        } else {
          console.error(
            'Erreur lors de la récupération des pays',
            response.statusText
          );
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des pays', error);
      }
    };
    fetchCountries();
  }, []);

  const handleUpdate = () => {
    if (domain) {
      onUpdate({
        id: domain.id,
        liblelle,
        description,
        country
      });
    }
  };

  // function setAddress(value: string): void {
  //   throw new Error('Function not implemented.');
  // }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 1
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Mettre à jour le domaine
        </Typography>
        <TextField
          margin="normal"
          label="Libelle"
          fullWidth
          value={liblelle}
          onChange={e => setLiblelle(e.target.value)}
        />
        <TextField
          margin="normal"
          label="Description"
          fullWidth
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="country-select-label">Pays</InputLabel>
          <Select
            labelId="country-select-label"
            value={country || ''}
            label="Pays"
            onChange={e => setCountry(e.target.value)}
          >
            {countries.map(country => (
              <MenuItem key={country.id} value={country.libelle}>
                {country.libelle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            onClick={onClose}
            sx={{ mr: 2 }}
            variant="contained"
            color="primary"
          >
            Annuler
          </Button>
          <Button onClick={handleUpdate} variant="contained" color="primary">
            Mettre à jour
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ActivityUpdateModal;
