import Button from 'components/base/Button';
import { Form, Modal } from 'react-bootstrap';
import Dropzone from 'components/base/Dropzone';
interface ReviewModalProps {
  show: boolean;
  handleClose: () => void;
}

const ReviewModal = ({ show, handleClose }: ReviewModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="p-4">
        <div className="mb-3">
          <Form.Label className="form-label-header mb-2">Libelle</Form.Label>
          <input
            className="form-control"
            name="libelle"
            type="text"
            placeholder="Libelle"
          />
        </div>
        <div className="mb-3">
          <Form.Label className="form-label-header mb-2">Domaine</Form.Label>
          <Form.Select>
            <option value="salesReports">Sales Reports</option>
            <option value="hrReports">HR Reports</option>
            <option value="marketingReports">Marketing Reports</option>
            <option value="administrativeReports">
              Administrative Reports
            </option>
          </Form.Select>
        </div>
        <div className="mb-3">
          <Form.Label className="form-label-header mb-2">Activités</Form.Label>
          <Form.Select>
            <option value="salesReports">Sales Reports</option>
            <option value="hrReports">HR Reports</option>
            <option value="marketingReports">Marketing Reports</option>
            <option value="administrativeReports">
              Administrative Reports
            </option>
          </Form.Select>
        </div>
        <div className="mb-3">
          <h5 className="text-body-highlight mb-3">Description</h5>
          <Form.Control as="textarea" rows={5} />
        </div>
        <Dropzone
          className="mb-3"
          size="sm"
          accept={{
            'image/*': ['.png', '.gif', '.jpeg', '.jpg']
          }}
        />
        <div className="d-flex flex-between-center">
          <button className="btn-danger" onClick={handleClose}>
            Close
          </button>
          <Button className="rounded-pill">Submit</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ReviewModal;
