import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
// import Ecommerce from 'pages/dashboard/ecommerce';
// import ProjectManagement from 'pages/dashboard/ProjectManagement';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import SimpleSignIn from 'pages/pages/authentication/simple/SignIn';
import SimpleSignOut from 'pages/pages/authentication/simple/SignOut';
import SimpleResetPassword from 'pages/pages/authentication/simple/ResetPassword';
import SimpleLockScreen from 'pages/pages/authentication/simple/LockScreen';
import SimpleTwoFA from 'pages/pages/authentication/simple/TwoFA';
import SimpleForgotPassword from 'pages/pages/authentication/simple/ForgotPassword';
// import Showcase from 'pages/Showcase';
import CreateCourses from 'pages/pages/createCourses';
import Partenaire from 'pages/pages/partenaire';
import Pays from 'pages/pages/pays';
import Video from 'pages/resource/video';
import Document from 'pages/resource/document';
import Offre from 'pages/resource/offre';
import Courses from 'pages/resource/courses';
import Unity from 'pages/resource/unity';
import Chapter from 'pages/resource/chapter';
import Parts from 'pages/resource/parts';
import Domaine from 'pages/pages/domaine';
import Quizs from 'pages/resource/quizs';
import App from 'App';
// import Crm from 'pages/dashboard/Crm';
import Activite from 'pages/pages/activite';
import Members from 'pages/pages/membre';
import Ecommerce from 'pages/dashboard/ecommerce';
const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      // {
      //   path: '/',
      //   element: (
      //     <MainLayoutProvider>
      //       <MainLayout />
      //     </MainLayoutProvider>
      //   ),

      // },
      {
        path: '/',
        element: <SimpleSignIn />
      },
      {
        path: '/dashboard',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Ecommerce />
          }
        ]
      },
      {
        path: '/auth',
        children: [
          {
            path: 'login',
            element: <SimpleSignIn />
          },
          {
            path: 'deconnexion',
            element: <SimpleSignOut />
          },
          {
            path: 'forgot-password',
            element: <SimpleForgotPassword />
          },
          {
            path: 'reset-password',
            element: <SimpleResetPassword />
          },
          {
            path: 'lock-screen',
            element: <SimpleLockScreen />
          },
          {
            path: '2FA',
            element: <SimpleTwoFA />
          }
        ]
      },
      {
        path: '/resource',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'video',
            element: <Video />
          },
          {
            path: 'document',
            element: <Document />
          },
          {
            path: 'offre',
            element: <Offre />
          },
          {
            path: 'courses/:id',
            element: <Courses />
          },
          {
            path: 'unity',
            element: <Unity />
          },
          {
            path: 'chapter',
            element: <Chapter />
          },
          {
            path: 'parts',
            element: <Parts />
          },
          {
            path: 'quizs',
            element: <Quizs />
          }
        ]
      },
      {
        path: '/pages',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: 'activites',
            element: <Activite />
          },
          {
            path: 'domaines',
            element: <Domaine />
          },
          {
            path: 'members',
            element: <Members />
          },
          {
            path: 'partenaires',
            element: <Partenaire />
          },
          {
            path: 'pays',
            element: <Pays />
          },
          {
            path: 'create/courses',
            element: <CreateCourses />
          }
        ]
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
