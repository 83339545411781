import { ColumnDef } from '@tanstack/react-table';
import { Member } from 'data/members';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import axios from 'axios';
import ActivityDeleteModal from 'components/modals/ActivityDeleteModal';
import DomainUpdateModal from 'components/modals/DomainUpdateModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const membersTablecolumns: ColumnDef<Member>[] = [];
interface Domain {
  id: number;
  liblelle: string;
  description: string;
  country: string;
}
const DomainsTable = () => {
  // Popup de suppression d'activité
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    id: number;
    liblelle: string;
  } | null>(null);

  const handleOpenModal = (item: { id: number; liblelle: string }) => {
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        const response = await fetch(
          `http://localhost:3002/api/v1/domain/${itemToDelete.id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.ok) {
          toast.success('Domaine supprimer avec succès');
          console.log('Item supprimé');
          // Mettre à jour l'état ou effectuer d'autres actions après la suppression
        } else {
          toast.error('Erreur produit lors de la suppression');
          console.error('Erreur de suppression', response.statusText);
        }
      } catch (error) {
        toast.error('Erreur produit lors de la suppression');
        console.error('Erreur de suppression', error);
      } finally {
        setIsModalOpen(false);
        window.location.reload();
      }
    }
  };

  // Popup de mise à jour d'activité
  const [domainToUpdate, setDomainToUpdate] = useState<{
    id: number;
    liblelle: string;
    description: string;
    country: string;
  } | null>(null);

  const handleUpdateOpenModal = (domain: {
    id: number;
    liblelle: string;
    description: string;
    country: string;
  }) => {
    setDomainToUpdate(domain);
    setIsModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsModalOpen(false);
    setDomainToUpdate(null);
  };

  const handleUpdateDomain = async (updatedDomain: {
    id: number;
    liblelle: string;
    description: string;
    country: string;
  }) => {
    try {
      const response = await fetch(
        `http://localhost:3002/api/v1/domain/${updatedDomain.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            liblelle: updatedDomain.liblelle,
            description: updatedDomain.description,
            country: updatedDomain.country
          })
        }
      );

      if (response.ok) {
        toast.success('Domaine mise à jour avec succès');
        console.log('Domaine updated successfully', response);
        // Mettre à jour l'état ou effectuer d'autres actions après la mise à jour
      } else {
        toast.error('Error de mise à jour');
        console.error('Erreur de mise à jour', response.statusText);
      }
    } catch (error) {
      toast.error('Error de mise à jour');
      console.error('Erreur de mise à jour', error);
    } finally {
      setIsModalOpen(false);
      window.location.reload();
    }
  };

  /// La récupération des domaines
  const [domains, setDomains] = useState<Domain[]>([]);
  const [showFullDescription, setShowFullDescription] = useState(false);

  useEffect(() => {
    async function fetchDomains() {
      try {
        const response = await axios.get('http://localhost:3002/api/v1/domain');
        console.log('data :', response.data);
        setDomains(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchDomains();
  }, []);

  return (
    <div id="tableExample">
      <div className="table-responsive">
        <table className="table table-sm fs--1 mb-0">
          <thead>
            <tr>
              <th className="sort border-top ps-3" data-sort="number">
                N°
              </th>
              <th className="sort border-top ps-3" data-sort="libelle">
                Libellés
              </th>
              <th className="sort border-top" data-sort="description">
                Descriptions
              </th>
              <th className="sort border-top" data-sort="description">
                Pays
              </th>
              <th
                className="sort text-end align-middle pe-0 border-top"
                scope="col"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {domains.map(domain => (
              <tr key={domain.id}>
                <td className="align-middle ps-3 number">{domain.id}</td>
                <td className="align-middle libelle">{domain.liblelle}</td>
                <td className="align-middle description">
                  {showFullDescription
                    ? domain.description
                    : `${domain.description.slice(0, 100)}...`}
                  {domain.description.length > 100 && (
                    <button
                      className="btn btn-link p-0 ms-2"
                      onClick={() =>
                        setShowFullDescription(!showFullDescription)
                      }
                    >
                      {showFullDescription ? 'Voir moins' : 'Voir plus'}
                    </button>
                  )}
                </td>
                <td className="align-middle description">{domain.country}</td>
                <td className="align-middle white-space-nowrap text-end pe-0">
                  <div className="font-sans-serif btn-reveal-trigger position-static">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="me-2"
                      onClick={() =>
                        handleOpenModal({
                          id: domain.id,
                          liblelle: domain.liblelle
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="me-2"
                      onClick={() =>
                        handleUpdateOpenModal({
                          id: domain.id,
                          liblelle: domain.liblelle,
                          description: domain.description,
                          country: domain.country
                        })
                      }
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {itemToDelete && (
        <ActivityDeleteModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          itemName={itemToDelete.liblelle}
        />
      )}
      {domainToUpdate && (
        <DomainUpdateModal
          open={isModalOpen}
          onClose={handleUpdateCloseModal}
          onUpdate={handleUpdateDomain}
          domain={domainToUpdate}
        />
      )}
    </div>
  );
};

export default DomainsTable;
