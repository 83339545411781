import TinymceEditor from 'components/base/TinymceEditor';
import { UilPlus } from '@iconscout/react-unicons';
import Modal from 'react-bootstrap/Modal';
import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Add axios for making HTTP requests
import { toast } from 'react-toastify';
// import DatePicker from 'components/base/DatePicker';
// import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Course {
  id: number;
  name: string;
  categoryIds: number[];
  level: string;
  duration: string;
  description: string;
  courseOverviewDescription: string;
}

const CreateCourses = () => {
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [categories, setCategories] = useState([{ id: '', name: '' }]); // Store categories retrieved from API
  const [course, setCourse] = useState<Course>({
    id: 0,
    name: '',
    level: '',
    duration: '',
    categoryIds: [],
    description: '',
    courseOverviewDescription: ''
  }); // Store course data

  const token = localStorage.bearerToken;

  const headers = {
    Authorization: `Bearer ${token}`
  };
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchCategories() {
      try {
        // const token = getBearerToken();
        const response = await axios.get(
          'https://testapi.clinicare-app.com/api/v1/course-category',
          { headers }
        );
        console.log('data :', response.data.data);
        setCategories(response.data.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCategories();
  }, []);

  const handleCreateCategory = () => {
    // Create a new category using the API
    axios
      .post(
        'https://testapi.clinicare-app.com/api/v1/course-category',
        {
          name: newCategory
        },
        { headers }
      )
      .then(response => {
        setCategories([...categories, response.data]); // Add new category to the list
        setNewCategory('');
        console.log(setCategories);
        setShowModal(false);
        toast.success('Catégorie créée avec succès');
      })
      .catch(error => {
        console.error(error);
        toast.error('Erreur de création de catégorie');
      });
  };

  const handleCreateCourse = () => {
    // Create a new course using the API
    axios
      .post(
        'https://testapi.clinicare-app.com/api/v1/course',
        {
          name: course.name,
          level: course.level,
          duration: course.duration,
          categories: course.categoryIds,
          description: course.description,
          courseOverviewDescription: course.courseOverviewDescription
        },
        { headers }
      )
      .then(response => {
        console.log('created:', response.data);
        toast.success('Création effectuée avec succès');
        const courseId = response.data.id;
        navigate(`/resource/courses/${courseId}`);
        // Redirect to course list or display success message
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    if (
      event.target instanceof HTMLSelectElement &&
      event.target.multiple &&
      name === 'categoryIds'
    ) {
      const selectedOptions = Array.from(event.target.selectedOptions, option =>
        parseInt(option.value, 10)
      );
      setCourse(prevCourse => ({
        ...prevCourse,
        categoryIds: selectedOptions
      }));
    } else {
      setCourse(prevCourse => ({ ...prevCourse, [name]: value }));
    }
  };

  const handleEditorChange = (content: string) => {
    setCourse({ ...course, description: content });
  };

  const handleEditorDescriptionChange = (content: string) => {
    setCourse({ ...course, courseOverviewDescription: content });
  };

  return (
    <div>
      <form className="row g-3 needs-validation" noValidate>
        <div className="col-md-6">
          <label className="form-label" htmlFor="validationCustom01">
            Nom du cours
          </label>
          <input
            className="form-control"
            id="validationCustom01"
            type="text"
            name="name"
            value={course.name}
            onChange={handleInputChange}
            required
          />
          <div className="valid-feedback">Looks good!</div>
        </div>
        <div className="col-md-6">
          <label className="form-label" htmlFor="validationCustom04">
            Le niveau du cours
          </label>
          <select
            className="form-select"
            id="validationCustom04"
            name="level"
            value={course.level}
            onChange={handleInputChange}
            required
          >
            <option selected value="">
              Choose...
            </option>
            <option value="beginner">beginner</option>
            <option value="intermediate">intermediate</option>
            <option value="advanced">advanced</option>
          </select>
          <div className="invalid-feedback">Please select a valid state.</div>
        </div>
        <div className="col-md-6">
          <label className="form-label" htmlFor="validationCustom03">
            Durée du cours
          </label>
          <input
            className="form-control"
            id="validationCustom03"
            type="text"
            name="duration"
            value={course.duration}
            onChange={handleInputChange}
            required
          />
          <div className="invalid-feedback">Please provide a valid city.</div>
        </div>
        <div className="col-md-6">
          <label className="form-label" htmlFor="category">
            Catégorie
          </label>
          <div className="input-group">
            <select
              className="form-control"
              id="category"
              name="categoryId"
              multiple // Add multiple attribute to allow multiple selection
              value={course.categoryIds.map(String)}
              onChange={handleInputChange}
            >
              <option value="">Sélectionner une catégorie</option>
              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
            <div className="input-group-append">
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={() => setShowModal(true)}
              >
                <UilPlus />
              </button>
            </div>
          </div>
        </div>
        <div>
          <label className="form-label" htmlFor="validationCustom05">
            La description du cours
          </label>
          <TinymceEditor
            value={course.description}
            onChange={handleEditorChange}
          />
        </div>
        <div>
          <label className="form-label" htmlFor="validationCustom05">
            La description de l'aperçu du cours
          </label>
          <TinymceEditor
            value={course.courseOverviewDescription}
            onChange={handleEditorDescriptionChange}
          />
        </div>
      </form>
      <div className="col-12">
        <button className="btn btn-primary" onClick={handleCreateCourse}>
          Valider
        </button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Créer une nouvelle catégorie</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control"
            type="text"
            value={newCategory}
            onChange={e => setNewCategory(e.target.value)}
            placeholder="Nom de la catégorie"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleCreateCategory}>
            Créer
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateCourses;
