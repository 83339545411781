// import Button from 'components/base/Button';
import { Modal, Box, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';
interface DeleteModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  itemName: string;
}

const ActivityDeleteModal: React.FC<DeleteModalProps> = ({
  open,
  onClose,
  onConfirm,
  itemName
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Confirmation de suppression
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Êtes-vous sûr de vouloir supprimer {itemName} ?
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
          <Button
            onClick={onClose}
            sx={{ mr: 2 }}
            variant="contained"
            color="primary"
          >
            Annuler
          </Button>
          <Button onClick={onConfirm} variant="contained" color="error">
            Supprimer
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ActivityDeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired
};

export default ActivityDeleteModal;
