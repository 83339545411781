import { ColumnDef } from '@tanstack/react-table';
// import AdvanceTable from 'components/base/AdvanceTable';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
// import Avatar from 'components/base/Avatar';
import { Member } from 'data/members';
import { faTrashAlt, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import axios from 'axios';
import ActivityDeleteModal from 'components/modals/ActivityDeleteModal';
import ActivityUpdateModal from 'components/modals/ActivityUpdateModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const membersTablecolumns: ColumnDef<Member>[] = [];
interface Activity {
  libelle: string;
  id: number;
  title: string;
  content: string;
  countryId: number;
  departementId: number;
  domainId: number;
  categorieId: number;
  domainLibelle: string;
  departementLibelle: string;
  countryLibelle: string;
}
const ActivitesTable = () => {
  // Popup de suppression d'activité
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    id: number;
    libelle: string;
  } | null>(null);

  const handleOpenModal = (item: { id: number; libelle: string }) => {
    setItemToDelete(item);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (itemToDelete) {
      try {
        const response = await fetch(
          `http://localhost:3003/api/v1/activity/${itemToDelete.id}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );

        if (response.ok) {
          toast.success('Activité supprimer avec succès');
          console.log('Item supprimé');
          // Mettre à jour l'état ou effectuer d'autres actions après la suppression
        } else {
          toast.error('Erreur produit lors de la suppression');
          console.error('Erreur de suppression', response.statusText);
        }
      } catch (error) {
        toast.error('Erreur produit lors de la suppression');
        console.error('Erreur de suppression', error);
      } finally {
        setIsModalOpen(false);
        window.location.reload();
      }
    }
  };

  // Popup de mise à jour d'activité
  const [activityToUpdate, setActivityToUpdate] = useState<{
    id: number;
    title: string;
    content: string;
    domainId: number;
    departementId: number;
    countryId: number;
  } | null>(null);

  const handleUpdateOpenModal = (activity: {
    id: number;
    title: string;
    content: string;
    domainId: number;
    departementId: number;
    countryId: number;
  }) => {
    setActivityToUpdate(activity);
    setIsModalOpen(true);
  };

  const handleUpdateCloseModal = () => {
    setIsModalOpen(false);
    setActivityToUpdate(null);
  };

  const handleUpdateActivity = async (updatedActivity: {
    id: number;
    title: string;
    content: string;
    domainId: number;
    departementId: number;
    countryId: number;
  }) => {
    try {
      const response = await fetch(
        `http://localhost:3003/api/v1/activity/${updatedActivity.id}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            title: updatedActivity.title,
            content: updatedActivity.content,
            domainId: updatedActivity.domainId,
            departementId: updatedActivity.departementId,
            countryId: updatedActivity.countryId
          })
        }
      );

      if (response.ok) {
        toast.success('Activité mise à jour avec succès');
        console.log('Activity updated successfully', response);
        // Mettre à jour l'état ou effectuer d'autres actions après la mise à jour
      } else {
        toast.error('Error de mise à jour');
        console.error('Erreur de mise à jour', response.statusText);
      }
    } catch (error) {
      toast.error('Error de mise à jour');
      console.error('Erreur de mise à jour', error);
    } finally {
      setIsModalOpen(false);
      window.location.reload();
    }
  };

  const [activities, setActivity] = useState<Activity[]>([]);
  const [showFullContent, setShowFullContent] = useState(false);

  useEffect(() => {
    async function fetchActivity() {
      try {
        const response = await axios.get(
          'http://localhost:3003/api/v1/activity'
        );
        console.log('data :', response.data);
        setActivity(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchActivity();
  }, []);

  return (
    <div
      id="tableExample"
      data-list='{"valueNames":["name","email","age"],"page":5,"pagination":true}'
    >
      <div className="table-responsive">
        <table className="table table-sm fs--1 mb-0">
          <thead>
            <tr>
              <th className="sort border-top ps-3" data-sort="number">
                N°
              </th>
              <th className="sort border-top ps-3" data-sort="libelle">
                Libellés
              </th>
              <th className="sort border-top" data-sort="description">
                Descriptions
              </th>
              <th className="sort border-top" data-sort="domain">
                Domaine
              </th>
              <th className="sort border-top" data-sort="pdf">
                Pays
              </th>
              <th className="sort border-top" data-sort="picture">
                Departement
              </th>
              <th className="sort border-top" data-sort="pdf">
                Catégorie
              </th>
              <th
                className="sort text-end align-middle pe-0 border-top"
                scope="col"
              >
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody className="list">
            {activities.map(activity => (
              <tr key={activity.id}>
                <td className="align-middle ps-3 number">{activity.id}</td>
                <td className="align-middle libelle">{activity.title}</td>
                <td className="align-middle description">
                  {showFullContent
                    ? activity.content
                    : `${activity.content.slice(0, 100)}...`}
                  {activity.content.length > 100 && (
                    <button
                      className="btn btn-link p-0 ms-2"
                      onClick={() => setShowFullContent(!showFullContent)}
                    >
                      {showFullContent ? 'Voir moins' : 'Voir plus'}
                    </button>
                  )}
                </td>
                <td className="align-middle libelle">
                  {activity.domainLibelle}
                </td>
                <td className="align-middle country">
                  {activity.countryLibelle}
                </td>
                <td className="align-middle departement">
                  {activity.departementLibelle}
                </td>
                <td className="align-middle category">
                  {activity.categorieId}
                </td>
                <td className="align-middle white-space-nowrap text-end pe-0">
                  <div className="font-sans-serif btn-reveal-trigger position-static">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="me-2"
                      onClick={() =>
                        handleOpenModal({
                          id: activity.id,
                          libelle: activity.libelle
                        })
                      }
                    />
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="me-2"
                      onClick={() =>
                        handleUpdateOpenModal({
                          id: activity.id,
                          title: activity.title,
                          content: activity.content,
                          departementId: activity.departementId,
                          domainId: activity.domainId,
                          countryId: activity.countryId
                        })
                      }
                    />
                  </div>{' '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {itemToDelete && (
        <ActivityDeleteModal
          open={isModalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirmDelete}
          itemName={itemToDelete.libelle}
        />
      )}
      {activityToUpdate && (
        <ActivityUpdateModal
          open={isModalOpen}
          onClose={handleUpdateCloseModal}
          onUpdate={handleUpdateActivity}
          activity={activityToUpdate}
        />
      )}
    </div>
  );
};

export default ActivitesTable;
